<template>
  <div class="page-table scrollable only-y mainDiv mb-30">
    <div class="card-base baslikBg animated fadeInUp">Görev Listesi
      <div class="tanimlamaButon">
        <el-tooltip :content='$t("src.views.apps.genel.refresh")' :open-delay="500" placement="top">
          <el-button v-on:click="refreshPage()" style="margin-left: 15px !important" type="text"
                     icon="el-icon-refresh"></el-button>
        </el-tooltip>
      </div>
    </div>
    <div class="page-header card-base card-shadow--medium animated fadeInUp" v-loading="loading || stateLoading"
         :element-loading-text='loading ? $t("src.views.apps.proje.liste.loading") : $t("src.views.apps.proje.liste.stateLoading")'
         element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 1)">
      <el-row class="mt-0 mb-10">
        <el-col :lg="12" :md="12" :sm="24" :xs="24">
          <div>
            <el-radio-group size="small" v-on:change="handleChange($event)" v-model="radio" fill="#E5F5F9"
                            text-color="gray" class="kapsam-list-radio-group">
              <el-radio-button label="0">
                <i class="mdi mdi-clipboard-text mdi-18px" style="color: #ecab20; margin-right: 5px"></i>
                {{ $t("src.views.apps.proje.liste.onay") }}
              </el-radio-button>
              <el-radio-button label="1">
                <i class="mdi mdi-clipboard-text mdi-18px" style="color: #13ce66; margin-right: 5px"></i>
                {{ $t("src.views.apps.proje.liste.active") }}
              </el-radio-button>
              <el-radio-button label="2">
                <i class="mdi mdi-clipboard-text mdi-18px" style="color: #13ce66; margin-right: 5px"></i>
                Sözleşme Bekliyor
              </el-radio-button>
              <el-radio-button label="3">
                <i class="mdi mdi-clipboard-text mdi-18px" style="color: #20a1ec; margin-right: 5px"></i>
                {{ $t("src.views.apps.proje.liste.aski") }}
              </el-radio-button>
              <el-radio-button label="4">
                <i class="mdi mdi-clipboard-text mdi-18px" style="color: #ec205f; margin-right: 5px"></i>
                {{ $t("src.views.apps.proje.liste.iptal") }}
              </el-radio-button>
              <el-radio-button label="5">
                <i class="mdi mdi-clipboard-text mdi-18px" style="color: #8b6010; margin-right: 5px"></i>
                {{ $t("src.views.apps.proje.liste.tamamlandi") }}
              </el-radio-button>
            </el-radio-group>
          </div>
        </el-col>
        <el-col :lg="12" :md="12" :sm="24" :xs="24">
          <el-col :lg="24" :md="24" :sm="24" :xs="24">
            <ul :class="isDesktop ? 'islemler' : 'islemler2'" v-if="(yetkiTuru == '1' || yetkiTuru == '2')">
              <li class="icon-incele"><i class="mdi mdi-magnify"></i> İncele</li>
              <li class="icon-tutanak-olustur"><i class="mdi mdi-pen-plus"></i> Tutanak Oluştur</li>
              <li class="icon-tutanak-goruntule"><i class="mdi mdi-file-document"></i> Tutanakları Görüntüle</li>
              <!--              <li class="icon-tamamlandi"><i class="mdi mdi-file-document"></i> Tamamlandı Olarak İşaretle</li>-->
              <!--              <li class="icon-iptal"><i class="mdi mdi-file-document"></i> İptal Olarak İşaretle</li>-->
            </ul>
          </el-col>
        </el-col>
        <br v-if="!isDesktop"/>
        <br v-if="!isDesktop"/>
      </el-row>
      <el-table v-loading="searchLoading" border stripe :data="projeList" style="width: 100%"
                :element-loading-text='"Projeler Filtreleniyor. Lütfen Bekleyiniz."'
                element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 1)">
        <el-table-column>
          <template slot="header">
          </template>
          <el-table-column :label='$t("src.views.apps.genel.sira")' type="index" :index="indexMethod" width="50">
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header">
          </template>
          <el-table-column prop="projeID" :label='$t("src.views.apps.proje.liste.table.id")' width="80">
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input clearable v-on:clear="projeAra"
                      :placeholder='$t("src.views.apps.proje.liste.search.searchCompanyName")' v-model="firmaAdiAra"
                      v-debounce:500ms="projeAra" size="mini"></el-input>
          </template>
          <el-table-column prop="musteriAdi" :label='$t("src.views.apps.musteri.liste.table.firmaAdi")'
                           width="200">
            <template slot-scope="props">
              <div class="new-line-text">
                {{ props.row.musteriAdi }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input clearable v-on:clear="projeAra"
                      :placeholder='$t("src.views.apps.proje.liste.search.searchName")' v-model="adAra"
                      v-debounce:500ms="projeAra" size="mini"></el-input>
          </template>
          <el-table-column prop="projeBaslik" :label='$t("src.views.apps.proje.liste.table.projeAdi")' width="400">
            <template slot-scope="props">
              <div class="new-line-text">
                {{ props.row.projeBaslik }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input clearable v-on:clear="projeAra"
                      :placeholder='$t("src.views.apps.proje.liste.search.searchArea")' v-model="alanAra"
                      v-debounce:500ms="projeAra" size="mini"></el-input>
          </template>
          <el-table-column prop="alan" :label='$t("src.views.apps.proje.liste.table.alan") + " (m²)"' width="200">
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-date-picker @change="projeAra"
                            :placeholder='$t("src.views.apps.proje.liste.search.searchStartDate")'
                            v-model="baslangicTarihiAra" format="yyyy-MM-dd" value-format="yyyy-MM-dd" size="mini"
                            class="select-wide" type="date"></el-date-picker>
          </template>
          <el-table-column prop="baslangicTarihi" :label='$t("src.views.apps.proje.liste.table.baslangicTarihi")'
                           width="180">
            <template slot-scope="scope">
              {{ new Date(scope.row.baslangicTarihi).toLocaleDateString() }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-date-picker @change="projeAra"
                            :placeholder='$t("src.views.apps.proje.liste.search.searchEndDate")'
                            v-model="bitisTarihiAra"
                            format="yyyy-MM-dd" value-format="yyyy-MM-dd" size="mini" class="select-wide"
                            type="date"></el-date-picker>
          </template>
          <el-table-column prop="bitisTarihi" :label='$t("src.views.apps.proje.liste.table.bitisTarihi")'
                           width="180">
            <template slot-scope="scope">
              {{ new Date(scope.row.bitisTarihi).toLocaleDateString() }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <el-table-column :label='$t("src.views.apps.genel.islem")'>
            <template slot-scope="scope">
              <div class="kapsam-list-islem-area">
                <el-tooltip class="item" effect="dark" content="İncele" placement="left">
                  <el-button plain size="mini" style="margin: 5px 0 0 5px;" icon="el-icon-search" type="primary"
                             @click="updatePage(scope.row)"/>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="Tutanak Oluştur" placement="bottom">
                  <el-button @click="tutanakAta(scope.row)" plain size="mini"
                             style="margin: 5px 0 0 10px;background-color: #bec9ff70;" type="primary">
                    <i class="mdi mdi-pen-plus"/>
                  </el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="Tutanakları Listele" placement="bottom">
                  <el-button @click="tutanakListele(scope.row)" plain size="mini"
                             style="margin: 5px 0 0 10px;background-color: #bec9ff70;"
                             icon="el-icon-document" type="primary">
                  </el-button>
                </el-tooltip>
                <el-dropdown @command="changeGorevDurum(scope.row,$event)" size="small" trigger="click"
                             class="proje-durum-dropdown">
                  <span class="el-dropdown-link">Durum<i class="el-icon-arrow-down el-icon--right"></i></span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="0" :disabled="selectIcon == 0">Onay Bekliyor</el-dropdown-item>
                    <el-dropdown-item command="1" :disabled="selectIcon == 1">Aktif
                    </el-dropdown-item>
                    <el-dropdown-item command="2" :disabled="selectIcon == 2">Sözleşme Bekliyor
                    </el-dropdown-item>
                    <el-dropdown-item command="3" :disabled="selectIcon == 3">Askıya Alındı
                    </el-dropdown-item>
                    <el-dropdown-item command="4" :disabled="selectIcon == 4">İptal Edildi
                    </el-dropdown-item>
                    <el-dropdown-item command="5" :disabled="selectIcon == 5">Tamamlandı
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <div v-if="projeList.length == [] && isDesktop"
           style="width: 100%; height: 50px !important; display: flex; justify-content: center; align-items: center">
        {{ $t("src.views.apps.messages.noData") }}
      </div>
      <div v-if="projeList.length == [] && !isDesktop">
        {{ open() }}
      </div>
      <el-pagination background layout="sizes,prev, pager, next, jumper, total" :total="total"
                     @size-change="handleSizeChange" @current-change="pageChange" :page-sizes="[10, 20, 50, 100]"
                     :current-page.sync="page" :next-click.sync="page" :prev-click.sync="page" @next-click="pageChange"
                     @prev-click="pageChange">
      </el-pagination>
    </div>
    <tutanak-list-modal v-if="dialogVisibleTutanakList" :dialogvisible.sync="dialogVisibleTutanakList"
                        :data="projeTutanakData"/>
  </div>
</template>

<script>
import kapsamService from '../../../WSProvider/KapsamService'
import EventBus from '@/components/event-bus'
import notification from '../../../notification'
import functions from '../../../functions'
import tutanakService from "@/WSProvider/TutanakService";
import TutanakListModal from "@/components/TutanakListModal";

var moment = require('moment');

export default {
  name: "ProjeListesi",
  components: {TutanakListModal},
  data() {
    return {
      dialogVisibleTutanakList: false,
      projeTutanakData: {
        projeID: '',
        projeAdi: '',
        tutanakList: [],
        count: 0,
      },
      projeTutanakCount: 0,
      projeID: '',
      isDesktop: false,
      loading: false,
      stateLoading: false,
      searchLoading: false,
      editDialogLoading: false,
      editDialog: false,
      editDialogData: {},
      projeList: [],
      radio: '1',
      projeForm: {
        eposta: "",
        adSoyad: "",
        telefon: "",
        tc: "",
        dogumTarihi: "",
        sozlesmeID: ""
      },
      rulesProje: {},
      selectIcon: '1',
      sizes: 10,
      page: 0,
      postPage: 0,
      total: 4,
      firmaAdiAra: "",
      adAra: "",
      alanAra: "",
      ulkeAra: "",
      ilAra: "",
      ilceAra: "",
      baslangicTarihiAra: "",
      bitisTarihiAra: "",
      sozlesmeler: [],

    }
  },
  created() {
    this.checkDevice();
    if (localStorage.getItem("userDataBGSurec")) {
      this.yetkiTuru = this.$store.getters.getYetkiTuru;
      this.yetkiListesi = this.$store.getters.getYetkiListesi;
      //console.log(this.yetkiListesi)
    }
  },
  mounted() {
    this.getProjeListesi();
    window.addEventListener('resize', this.checkDevice);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkDevice);
  },
  methods: {
    tutanakListele(data) {
      // this.$store.commit("setTutanakData", {musteriID: data.musteriID, projeID: data.projeID})
      try {
        this.projeTutanakData.projeID = data.projeID
        this.projeTutanakData.projeAdi = data.projeBaslik
        this.dialogVisibleTutanakList = true
        tutanakService.tutanakListesiAra(data.projeID, 1, 0, 10, '', '', '', '', '', '', '').then(response => {
          if (response.status == 200) {
            this.projeTutanakData.tutanakList = response.data
            this.projeTutanakData.count = response.count
          }
          localStorage.setItem("userDataBGSurec", response.token);
        }).catch(err => {
          if (err.responseJSON) {
            let error = err.responseJSON
            if (error.status == 401) {
              notification.Status(150, this);
            } else if (error.status == 404) {
              this.projeTutanakData.tutanakList = []
              this.projeTutanakData.count = 0
            }
          } else {
            //notification.Status(503, this)
          }
        })
      } catch (e) {
        // notification.Status("danger", this, "An error occurred during the customer search process.")
      }
    },
    tutanakAta(data) {
      this.$store.commit("setTutanakData", data)
      functions.routeSayfa("Tutanak Tanımla", this)
    },
    checkDevice() {
      this.isDesktop = window.innerWidth >= 768;
    },
    changeGorevDurum(selection, durum) {
      this.$confirm("Görevin durumunu güncellemek istediğinizden emin misiniz?", this.$t("src.views.apps.genel.uyari"), {
        confirmButtonText: this.$t("src.views.apps.genel.yes"),
        cancelButtonText: this.$t("src.views.apps.genel.no"),
        type: 'warning'
      }).then(() => {
        try {
          this.stateLoading = true;
          kapsamService.yoneticininProjeDurumDegistir(selection.projeID, durum).then(response => {
            if (response.status == 200) {
              this.getProjeListesi();
            }
            this.stateLoading = false;
            notification.Status("success", this, response.msg);
            localStorage.setItem("userDataBGSurec", response.token);
          }).catch(err => {
            if (err.responseJSON) {
              let error = err.responseJSON
              if (error.status == 401) {
                notification.Status(150, this);
              } else notification.Status("errorMsg", this, error.errMsg)
            } else {
              //notification.Status(503, this)
            }
            this.stateLoading = false;
          })
        } catch (e) {
          // notification.Status("success", this, "An error occurred while fetching the customer number.");
          this.stateLoading = false;
        }
      })
    },
    open() {
      setTimeout(() => {
        this.$alert('Gösterilecek veri bulunmamaktadır', 'Uyarı!', {
          confirmButtonText: 'OK',
        });

      }, 0);
    },
    projeAra() {
      this.postPage = 0;
      this.page = 1
      this.dateVisible = false;
      //siparisNo, adSoyad, telefon, country, city, state, baslangicTarihi, bitisTarihi, durum, baslangic, limit,
      if (this.firmaAdiAra.length > 0 || this.adAra.length > 0 || this.alanAra.length > 0 || this.ulkeAra.length > 0 || this.ilAra.length > 0 || this.ilceAra.length > 0 || this.baslangicTarihiAra || this.bitisTarihiAra) {
        try {
          this.searchLoading = true;
          //kullaniciAdi, adSoyad, mail, telefon, durum, baslangic, limit)
          kapsamService.projeFilter(this.firmaAdiAra, this.adAra, this.alanAra, this.baslangicTarihiAra, this.bitisTarihiAra, this.selectIcon, this.postPage, this.sizes).then(response => {
            if (response.status == 200) {
              this.projeList = response.data;
              this.total = response.count
            } else {
              this.projeList = []
            }
            localStorage.setItem("userDataBGSurec", response.token);
            this.searchLoading = false;
          }).catch(err => {
            this.projeList = []
            if (err.responseJSON) {
              let error = err.responseJSON
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.projeList = []
              }
            } else {
              //notification.Status(503, this)
            }
            this.searchLoading = false;
          })
        } catch (e) {
          // notification.Status("danger", this, "An error occurred during the customer search process.")
          this.searchLoading = false
        }
      } else {
        this.getProjeListesi();
        // this.getProjeSayisi();
      }
    },
    refreshPage() {
      this.page = 1;
      this.postPage = 0;

      // this.getProjeSayisi();
      this.getProjeListesi();
    },
    Guncelle(formName) {
      this.$refs[formName].validate((valid, errors) => {
        if (valid) {
          //adSoyad, mail, telefon, projeID
          this.$confirm("Projeyi güncellemek istediğinizden emin misiniz?", this.$t("src.views.apps.genel.uyari"), {
            confirmButtonText: this.$t("src.views.apps.genel.yes"),
            cancelButtonText: this.$t("src.views.apps.genel.no"),
            type: 'warning'
          }).then(() => {
            try {
              this.editDialogLoading = true;
              kapsamService.projeGuncelle(this.projeForm.adSoyad, this.projeForm.eposta, this.projeForm.telefon, this.editDialogData.projeID).then(response => {
                if (response.status) {
                  this.vazgec(formName);
                  this.getProjeListesi(this.selectIcon)
                }
                notification.Status("success", this, response.msg);
                localStorage.setItem("userDataBGSurec", response.token);
                this.editDialogLoading = false;
              }).catch(err => {
                if (err.responseJSON) {
                  let error = err.responseJSON
                  if (error.status == 401) {
                    notification.Status(150, this);
                  } else notification.Status("errorMsg", this, error.errMsg)
                } else {
                  //notification.Status(503, this)
                }
                this.editDialogLoading = false;
              })
            } catch (e) {
              // notification.Status("danger", this, "An error occurred during the update process.")
              this.editDialogLoading = false
            }
          })
        } else {
          for (var k in errors) {
            errors[k].forEach((element) => {
              notification.Status("warning", this, element.message);
            })
          }
          this.editDialogLoading = false;
        }
      })
    },
    getProjeSayisi() {
      try {
        kapsamService.projeSayisi(this.selectIcon).then((response) => {
          if (response.status == 200) {
            localStorage.setItem("userDataBGSurec", response.token)
          }
          this.total = parseInt(response.data);
        }).catch(err => {
          if (err.responseJSON) {
            let error = err.responseJSON
            if (error.status == 401) {
              notification.Status(150, this);
            } else if (error.status == 404) {
              this.total = 0
            }
          } else {
            //notification.Status(503, this)
          }
        })
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer number.")
      }
    },
    getProjeListesi() {
      try {
        this.loading = true;
        kapsamService.projeListesi(this.selectIcon, this.postPage, this.sizes).then((response) => {
          localStorage.setItem("userDataBGSurec", response.token)
          if (response.status == 200) {
            this.total = parseInt(response.data.projeSayisi);
            this.projeList = response.data;
            this.total = parseInt(response.count);
          }
          this.loading = false;
        }).catch(err => {
          if (err.responseJSON) {
            let error = err.responseJSON
            if (error.status == 401) {
              notification.Status(150, this);
            } else if (error.status == 404) {
              this.projeList = [];
            } else notification.Status("errorMsg", this, error.errMsg)
          } else {
            //notification.Status(503, this)
          }
          this.loading = false;
        })
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false
      }
    },
    vazgec(formName) {
      this.$refs[formName].resetFields();
      this.editDialog = false;
      this.loading = false;
      this.stateLoading = false;
    },
    startUpdate() {
      this.projeForm.adSoyad = this.editDialogData.adSoyad;
      this.projeForm.eposta = this.editDialogData.mail;
      this.projeForm.telefon = this.editDialogData.telefon;
      this.projeForm.tc = this.editDialogData.tc;
      // this.projeForm.dogumTarihi = moment(this.editDialogData.dogumTarihi).format("DD-MM-YYYY");
    },
    updatePage(data) {
      this.$store.commit('changeProjeUpdateData', data.projeID)
      functions.routeSayfa("Kapsam Sözlesmeler", this)
    },
    routeProje(name, scope) {
      if (scope) {
        this.$store.commit('changeProjeUpdateData', scope);
      }
      functions.routeSayfa(name, this);
    },
    indexMethod(index) {
      return ((this.page - 1) * 10) + index + 1;
    },
    handleSizeChange(val) {
      this.sizes = val;
      this.getProjeListesi();
    },
    handleChange(event) {
      this.selectIcon = event;
      this.page = 1;
      this.postPage = 0;
      this.getProjeListesi();
      this.firmaAdiAra = ""
      this.adAra = ""
      this.alanAra = ""
      this.ulkeAra = ""
      this.ilAra = ""
      this.ilceAra = ""
      this.baslangicTarihiAra = ""
      this.bitisTarihiAra = ""
      document.querySelector('.scrollable').scrollTo({top: 0, behavior: 'smooth'});
    },
    pageChange(val) {
      this.postPage = (val - 1) * this.sizes;
      this.getProjeListesi();
    },
  }
}

</script>

<style lang="scss">
.el-table .info-row {
  background: rgba(125, 199, 225, 0.45);
}

.el-table .error-row {
  background: rgba(249, 0, 26, 0.08);
}

.el-table .success-row {
  background: rgba(3, 249, 0, 0.08);
}

.el-table .warning-row {
  background: rgba(245, 249, 0, 0.08);
}
</style>
<style lang="scss" scoped>

@media screen and (max-width: 1000px) {
  .islemler2 {
    display: flex !important;
  }
  .kapsam-list-radio-group {
    display: grid;
    margin-bottom: 20px;
  }
}

.kapsam-list-islem-area {
  display: flex;
  place-items: center;
}

.proje-durum-dropdown {
  margin-left: 20px;
  cursor: pointer;
}

.refresh {
  color: #fff;
  float: right;
  padding: 0 !important;
  transition: all 0.5s;
}

.refresh:hover {
  transform: rotate(360deg);
}

.el-pagination {
  margin-top: 20px;
  float: right;
}

.el-radio-button--mini .el-radio-button__inner label {
  position: relative;
  bottom: 5px;
}

/*
    .el-table .el-table__cell.is-right{
        position:fixed
    }*/

.kaldirilmis {
  background: #ec205f;
  color: white;
  font-weight: bold;
  font-size: 16px;
  padding: 10px
}
</style>
<style lang="scss">
.slide-fade-enter-active {
  transition: all .3s ease;
}

@media (min-width: 768px) {
  .el-radio-button--mini .el-radio-button__inner {
    padding: 0 10px !important;
    font-size: 11px;
    border-radius: 0;
  }
}

.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter,
.slide-fade-leave-to

  /* .slide-fade-leave-active below version 2.1.8 */
{
  transform: translateX(10px);
  opacity: 0;
}
</style>